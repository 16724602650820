.sobremi {
	display: block;
	align-content: center;
	justify-content: center;
	height: 78vh;
	margin: 0 6rem;
	overflow-y: auto;
	background-color: rgb(92, 90, 87);
}
.sobremi p {
	color: white;
	padding: 0.5rem 4.5rem;
	
}
@media (width< 700px) {
	.sobremi {
		margin: 0rem 2rem;
		height: 580px;
		width: 290px;
		font-size: 1rem;
	}
	.sobremi p {
		padding: 0.5rem;		
	}
}
