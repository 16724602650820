.error404 {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 70vh;
	color: red;
	font-weight: bold;
}
.error404
h1 {
	margin-bottom: 20px;
	font-size: 2rem;
}
.errorButton {
    padding: 0.5rem;
    border-radius: 5px;
    cursor: pointer;
}