.nav-container {
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px; 
    box-shadow: 0 0.1rem 0 0 rgba(0,0,0,0.1); 
    margin: 1rem 6rem;
}
.nav-container > div:first-child a {
    display: flex;
    font-weight: bold;       
    text-decoration: none;
    color: black;
    font-size: 1.3rem;
    
}
.menu-items a {
    text-decoration: none;
    color: black;
    display:inline-flexbox;
    justify-content: flex-end; 
    margin: 0 5rem   
}
.menu-items a:hover {
    font-weight: bold;
}
.active-link {
	text-decoration: none;
	font-weight: bold;
	transition: color 0.2s ease-in-out;
}
.megabot{
    font-size: 1.3rem;
    display: flex;
    align-items: center;
}
.logo {
    width: 3rem;
    height: 3rem;
    
}
.claim {
    font-size: 1rem;
    opacity: 40%;    
}
@media (width <700px){
    .nav-container {
        /* background-color: beige; */
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 2px; 
        box-shadow: 0 0.1rem 0 0 rgba(0,0,0,0.1); 
        margin: 1rem 0rem;
        width:320px;
        box-sizing: border-box;        
    }
    .menu-items a {
        text-decoration: none;
        color: black;
        display:inline-flexbox;
        justify-content: flex-end; 
        margin: 0;   
        font-size: 0.9rem;
    }
    span {
        font-size: 1rem;        
    }  
    .megabot {
        font-size: 1.1rem;        
        padding-left: 1rem;
    }
    .claim{
        font-size: 0.9rem;
        position: relative;
        bottom: 1px
        
    } 
}