* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html {
	background-color: #fff;
	background-image: linear-gradient(
			135deg,
			transparent 0%,
			#fff 25%,
			#fff 75%,
			transparent 100%
		),
		url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAAIklEQVQoU2N89+7dfwYsQEhIiBEkzDgkFGDzAbIY2Cv4AACvrBgJjYNGfwAAAABJRU5ErkJggg==);
}
body {
	font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
		Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
	font-size: 1rem;
}
@media (width<700px) {
	body {
		font-size: 0.5rem;
		background-color: transparent;
	}
}
