.footerContainer {
	display: flex;
	align-items: center;	
	align-content: center;	
}
.contactos {
	display: flex;
	justify-content: left;
	margin: 0.8rem 3rem 0 6rem;
	font-size: 0.8rem;
	align-items: center;
	font-weight: bold;	
}
.text {	
	margin-right: 0.51rem;	
	margin-bottom: 0.3rem
}
.contactos a {
	padding: 9px;
	font-size: 0.8rem;
	text-decoration: none;
	color: black;
}
.contactos a:hover {
	font-weight: bolder;
}
.whatsapp {
	height: 1.3rem;
}
.mail {
	height: 1.3rem;
}
.meeting {
	height: 1.1rem;
	margin-top: 0.4rem	
}
.facebook{
	height: 1rem;	
}
.reunionContainer{
	display: flex;
	align-items: center;
	
}
.textReunion {
	font-size: 0.8rem;
	font-weight: bold;
	margin-right: 0.5rem;
	
}

@media (max-width: 700px) {
	.footerContainer{
		display: block;
		margin-top:3rem;
		box-sizing: border-box; 
	}
	.contactos {
		display: flex;
		background-color: transparent; 
		width: 320px;
		margin: 1rem 0rem 0rem 1rem;
		padding: 1px;
	}
	.contactos a {
		padding: 4px;
	}
	.text {
		font-size: 0.7rem;		
	}
	.icon {
		margin-bottom: 0.5rem;
	}
	.contactos a:hover {
		font-weight: bold;
	}
	.reunionContainer{	
		margin: 0.4rem 0rem 0rem 1rem;		
		box-sizing: border-box; 
		display: flex;
		justify-content: left;
	}
	.textReunion{
		font-size: 0.7rem;
		margin-top: 0.05rem;		
		
	}
	.meeting {
		margin-top: 0rem
	}
}
