.chat-container {
	width: 98%;
	/* background-color: rgb(193, 154, 97); */
	overflow: hidden;
	height: 75vh;
	display: flex;
	flex-direction: column;
	margin: 1rem;
}
.scroll {
	/* border: solid; */
	width: fit-content;
	overflow-y: auto;
	scrollbar-width: none;
	height: 90%;
	max-height: 90%;
}
/* Oculta los scrollbars en Webkit (Chrome, Safari, etc.) */
.scroll::-webkit-scrollbar {
	display: none;
}

.chatbot-image {
	margin-right: 0.2rem;
	width: 1.5rem;
	height: 1.5rem;
}
.assistant-role {
	display: flex;
	align-items: center;
	background-color: white;
	color: black;
	width: fit-content;
	margin: 0rem 0.3rem;
	border-radius: 0.5rem;
}
.user-role {
	display: flex;
	align-items: center;
	background-color: rgb(221, 234, 221);
	color: black;
	width: fit-content;
	padding: 0.2rem;
	margin-right: 3rem;
	margin-left: auto;
	border-radius: 0.5rem;
	word-break: break-word;
}
.questions-container {
	/* background-color: rgb(208, 204, 211); */
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-content: center;
	gap: 1rem;
	margin-top: 1.2rem;
}
.hidden-question {
	visibility: hidden;
}
.visible-question {
	visibility: visible;
	border-radius: 0.5rem;
	padding: 0.5rem;
	width: 10rem;
	cursor: pointer;
	border-width: 0.01rem;
	box-shadow: 0.1rem 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
	margin: 0.2rem auto;
}
.visible-question:hover {
	background-color: rgb(16, 15, 15);
	color: white;
}
.inputContainer {
	/* height: 10%;
	max-height: 10%;
	display: flex;
	align-items: center;
	justify-content: right;
	width: 100%; */

	height: auto;
	min-height: 10%;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding: 10px 0;
}
.formulario {
	display: flex;
	/* background-color: beige; */
	width: 40rem; /* 38 rem */
	min-width: 21rem;
	margin: auto;
	border: solid;
	border-radius: 10px;
	border-width: 0.5px;
	position: relative;
	align-items: center;
}
.formulario:focus-within {
	border-width: 2px;
}
.inputText {
	height: 1.4rem;
	padding: 1.2rem 0.5rem;
	border-radius: 10px;
	width: 100%;
	border: none;
	background-color: transparent;
}
.inputText:focus {
	outline: none;
}
.img-preview {
	height: 25px;
	width: 25px;
	margin: auto;
}
.img-view {
	height: 3rem;
	width: 3rem;
	margin: auto;
	padding: 0.2rem;
}
.submitButton {
	cursor: pointer;
	border: none;
	background-color: transparent;
	position: relative;
	right: 0.5rem;
	padding-top: 0.2rem;
}
.submitButtonWithNoInput {
	border: none;
	background-color: transparent;
	opacity: 30%;
	position: absolute;
	right: 0.5rem;
	padding-top: 0.2rem;
}
.submitButtonWithNoInput[disabled]:hover::before {
	content: "Enviar Mensaje";
	position: absolute;
	bottom: 2.5rem;
	right: -0.5rem;
	background-color: black;
	color: white;
	padding: 5px;
	border-radius: 5px;
	font-size: 0.6rem;
}
.img-button {
	width: 100%;
	width: 1.7rem;
	margin-left: 2rem;
}
.attachContainer {
	display: flex;
	align-items: center;
}
.attachContainer:hover::after {
	content: "Adjuntar Imagen";
	position: absolute;
	bottom: 2.7rem;
	right: 34rem;
	background-color: black;
	color: white;
	padding: 5px;
	border-radius: 5px;
	font-size: 0.6rem;
	text-align: center;
	font-weight: bold;
}
.attachButton {
	cursor: pointer;
	margin-left: 0.5rem;
}
.broom {
	height: 1.5rem;
	cursor: pointer;
}
.broomContainer {
	position: relative;
}
.broomContainer:hover::after {
	content: "Borrar Historial";
	position: absolute;
	bottom: 2.6rem;
	right: -0.1rem;
	background-color: black;
	color: white;
	padding: 5px;
	border-radius: 5px;
	font-size: 0.6rem;
	text-align: center;
	font-weight: bold;
}

.audio-recorder-container {
	display: flex;
	align-items: center;
}

/* Estilos para los controles de grabación */
.audio-recorder-container > div {
	display: flex;
	align-items: center;
}

.audio-recorder-container button {
	background: none;
	border: none;
	cursor: pointer;
	padding: 0px 2px;
	background-color: transparent;
	
}
.audio-recorder-container img {
	width: 25px;	
}
.audio-recorder-container audio {
	height: 2rem;
	margin: 0
}
.trashContainer:hover::after{
	content: "Borrar Audio";
	position: absolute;
	bottom: 2.6rem;
	right: 19.5rem;
	background-color: black;
	color: white;
	padding: 5px;
	border-radius: 5px;
	font-size: 0.6rem;
	text-align: center;
	font-weight: bold;
}
.pauseContainer:hover::after{
	content: "Pausar Audio";
	position: absolute;
	bottom: 2.6rem;
	right: 2.5rem;
	background-color: black;
	color: white;
	padding: 5px;
	border-radius: 5px;
	font-size: 0.6rem;
	text-align: center;
	font-weight: bold;
}
.recordContainer:hover::after{
	content: "Parar Audio";
	position: absolute;
	bottom: 2.6rem;
	right: 0.3rem;
	background-color: black;
	color: white;
	padding: 5px;
	border-radius: 5px;
	font-size: 0.6rem;
	text-align: center;
	font-weight: bold;
}
.sendContainer:hover::after{
	content: "Enviar Audio";
	position: absolute;
	bottom: 2.6rem;
	right: -1.3rem;
	background-color: black;
	color: white;
	padding: 5px;
	border-radius: 5px;
	font-size: 0.6rem;
	text-align: center;
	font-weight: bold;
}
.resumeContainer:hover::after{
	content: "Reanudar Audio";
	position: absolute;
	bottom: 2.6rem;
	right: 0rem;
	background-color: black;
	color: white;
	padding: 5px;
	border-radius: 5px;
	font-size: 0.6rem;
	text-align: center;
	font-weight: bold;
}

@keyframes blink {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
.inputText.typing {
	animation: blink 1s infinite;
	color: black;
	font-weight: bolder;
}

@media (width<700px) {
	.chat-container {
		width: 270px;
		height: 580px;
		/* background-color: rgb(193, 154, 97); */
		overflow: hidden;
		display: flex;
		flex-direction: column;
		margin-top: 40px;
		margin-left: 0px;
	}
	.formulario {
		min-width: 10rem;
	}
	.assistant-role {
		margin: 0;
	}
	.user-role {
		margin: auto;
		max-width: 310px;
	}
	.attachButton {
		position: relative;
		right: 0.5rem;
		cursor: pointer;
	}
	.attachContainer:hover::after {
		content: "Adjuntar Imagen";
		position: absolute;
		bottom: 2.5rem;
		right: 14rem;
	}
	.broom {
		margin-left: 0.2rem
	}
}
