.homeContainer {
    /* background-color: rgb(125, 95, 158); */ 
	width: fit-content; 
	display: flex;
	justify-content: center;
	align-items: center;
    margin: 0rem 6rem; 
	height: 78vh 	/* Poner en 78 para que no se mueva */
}
.loading {
	padding: 80px;
}
.loader {
	height: 25px;
	margin-top: 15px	
}
.image{	
	aspect-ratio: 1/1;
	width: 30vw;
	margin: auto;	
}
.image:hover {
	cursor: pointer
}
.personaje:hover .hola{
	cursor: pointer;
	visibility: visible;
	font-weight: bold;
	position: relative;
	left: 15rem;
	top: 3rem;
}
.hola{
	visibility: hidden;
}

@media (width< 700px) {
	.image {
		display: none;
	}
	.homeContainer {
		width: 320px;
		margin: 0;
		height: 580px;
		box-sizing: border-box;
	}

}

/* GRID */
/* .homeContainer {
    background-color: rgb(125, 95, 158); 
	display: grid;
	grid-template-columns: 1fr 2fr;
	grid-template-areas:
	"personaje megabot";
	width: fit-content; 
	justify-content: center;
	align-items: center;
    margin: 0rem 6rem; 
	height: 80vh 	
}
.personaje{
	grid-area: personaje;
}
.megabot{
	grid-area: megabot;
}

@media (width <500px){
	.homeContainer {
		background-color: rgb(125, 95, 158); 
		display: grid;
		grid-template-columns: 1fr;
		grid-template-areas:
		"megabot";
		width: fit-content; 
		justify-content: center;
		align-items: center;
		margin: 0rem 6rem; 
		height: 80vh 	
	}	
} */